import { Link } from "react-router-dom";
import Linkedin from "../imgs/linkedin-logo.png";
import Github from "../imgs/github.png";

function Footer() {
  return (
    <div className="flex h-28 justify-between bg-white p-1 md:p-6 lg:p-6">
      <ul className="text-sm font-bold tracking-widest">
        <li className="mb-3">
          <Link to="/">HOME</Link>
        </li>
        <li>
          <Link to="/about">ABOUT</Link>
        </li>
        {/* <li><Link to="/city">CDMX GUIDE</Link></li> */}
      </ul>
      <div className="mr-4 text-2xl">
        <span className="mb-5 text-2xl text-slate-900">
          <a href="mailto:dante.alvarez.pr@gmail.com" className="animated-dark">
            dante.alvarez.pr@gmail.com
          </a>
        </span>
        <div className="mt-2 ml-36 flex w-1/3 justify-between">
          <a
            href="https://www.linkedin.com/in/dante-álvarez-p"
            rel="noreferrer"
            target="_blank"
          >
            <img alt="linkedin" src={Linkedin} width="30" />
          </a>
          <a
            href="https://github.com/danalvrz"
            rel="noreferrer"
            target="_blank"
          >
            <img alt="github" src={Github} width="30" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
