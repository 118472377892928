/* eslint-disable no-loop-func */
import Header from "./components/Header";
import Homepage from "./pages/Homepage";
import Aboutpage from "./pages/Aboutpage";
import ProjectPage from "./pages/Projectpage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import "./App.css";

function App() {
  document.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
      document.getElementById("App").classList.remove("opacity-0");
      document.getElementById("App").classList.add("opacity-100");
    }, 1);
  });

  return (
    <div
      id="App"
      className="App opacity-0 transition-all delay-150 duration-700 ease-out"
    >
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<Aboutpage />} />
          <Route path="/project/:projectId" element={<ProjectPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
