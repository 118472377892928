function Aboutpage() {
  window.scrollTo(0, 0);
  return (
    <main className="sm:flex-col md:grid md:grid-cols-4 md:grid-rows-5 lg:grid lg:grid-cols-5 lg:grid-rows-3">
      <div className="bg-dark-blue border-b-4 p-10 pt-20 sm:col-span-2 sm:row-start-1 md:col-span-4 md:col-start-1 md:row-span-2 lg:col-span-3 lg:col-start-1 lg:row-span-2 lg:row-start-1">
        <div className="mb-5">
          <p className="mb-3 text-base font-bold tracking-widest text-white">
            ABOUT ME
          </p>
          <p className="mb-6 text-lg leading-10 tracking-wider text-slate-300 md:text-2xl lg:text-3xl">
            I am a <span className="text-white">user-centered</span> full-stack{" "}
            <span className="text-white">developer</span>, industrial{" "}
            <span className="text-white">engineer</span>, and problem{" "}
            <span className="text-white">solver</span>. I am a{" "}
            <span className="text-white">fast learner</span>, with{" "}
            <span className="text-white">leadership</span> skills and a high
            sensitivity to identifying{" "}
            <span className="text-white">key elements</span> in a process.
            Additionally, I am a <span className="text-white">creative</span>{" "}
            thinker who is <span className="text-white">passionate </span>
            about the idea of permanent{" "}
            <span className="text-white">improvement</span> through{" "}
            <span className="text-white">design</span>. I think of
            accessibility, <span className="text-white">collaboration</span>,
            and integration as central requirements in development.
          </p>
        </div>
        <div className="my-3 w-fit lg:mt-10">
          <p className="mb-3 text-sm font-bold tracking-widest text-white">
            SEND ME A MESSAGE
          </p>
          <span className="mb-5 text-2xl text-slate-300 hover:text-white">
            <a
              href="mailto:dante.alvarez.pr@gmail.com"
              className="animated w-fit"
            >
              dante.alvarez.pr@gmail.com
            </a>
          </span>
        </div>
      </div>

      <div className="bg-dark-yellow border-b-4 p-10 sm:col-span-1 sm:row-start-2 md:col-span-2 md:col-start-1 md:row-span-1 md:row-start-3 lg:col-span-2 lg:col-start-4 lg:row-start-1">
        <p className="mb-3 text-base font-bold tracking-widest text-white">
          LANGUAGES
        </p>
        <ul className="mb-6 list-inside list-disc text-xl text-black">
          <li>JavaScript (ES5/ES6)</li>
          <li>Ruby</li>
          <li>HTML</li>
          <li>CSS</li>
        </ul>
      </div>
      <div className="bg-dark-orange border-b-4 p-10 sm:row-start-3 md:col-span-2 md:col-start-3 md:row-start-3 lg:col-span-2 lg:col-start-4 lg:row-start-2">
        <p className="mb-3 text-base font-bold tracking-widest text-white">
          FRAMEWORKS & TECH
        </p>
        <ul className="mb-6 list-inside list-disc text-xl text-black">
          <li>React</li>
          <li>Redux</li>
          <li>Ruby on rails</li>
          <li>Tailwind CSS</li>
          <li>Bootstrap</li>
        </ul>
      </div>
      <div className="bg-medium-orange border-b-4 p-10 md:col-span-2 md:row-start-4 lg:col-span-2 lg:col-start-4 lg:row-start-3">
        <p className="mb-3 text-base font-bold tracking-widest text-white">
          SKILLS
        </p>
        <ul className="mb-6 list-inside list-disc text-xl text-black">
          <li>Git</li>
          <li>Pair programming</li>
          <li>Agile Methodologies</li>
          <li>TTD</li>
        </ul>
      </div>

      <div className="bg-dark-yellow md:bg-medium-green border-b-4 p-10 md:col-span-4 md:col-start-1 md:row-start-5 lg:col-span-2 lg:col-start-2 lg:row-start-3">
        <div className="mb-5">
          <p className="mb-3 text-center text-base font-bold tracking-widest text-white">
            QUICK FUN FACTS
          </p>
          <p className="mb-6 text-center text-lg text-slate-900">
            I love to play guitar, to cook, and to roast my own coffee.
          </p>
        </div>
        <div className="mb-5">
          <p className="mb-3 text-center text-base font-bold tracking-widest text-white">
            I BELIEVE
          </p>
          <p className="mb-6 text-center text-lg text-slate-900">
            technology should make our lives better, and should be designed
            inclusively in all dimensions. It makes me happy to be part of this
            mission.
          </p>
        </div>
      </div>

      <div className="bg-medium-green md:bg-dark-yellow flex content-center justify-center border-b-4 p-10 md:col-span-2 md:row-start-4 lg:col-span-1 lg:col-start-1 lg:row-start-3 lg:p-0">
        <div className="mx-auto flex w-full items-center justify-center">
          <div className="bg-me h-60 w-10/12 rounded-full border-4 bg-cover bg-center bg-no-repeat md:m-0 md:w-3/4 lg:m-5 lg:w-11/12" />
        </div>
      </div>
    </main>
  );
}

export default Aboutpage;
