import ProjectCard from "../components/ProjectCard";
import projectsList from "../projects/projects";

function Homepage() {
  return (
    <main className="sm:flex-row  md:grid md:grid-cols-3 md:grid-rows-4 lg:grid lg:grid-cols-5 lg:grid-rows-3">
      <div className="bg-dark-orange border-b-4 p-10 pt-20 md:col-span-2 md:col-start-2 md:row-start-1 lg:col-span-2 lg:col-start-4">
        <div className="mb-5">
          <p className="mb-3 text-lg font-bold tracking-widest text-white">
            HOLA! NICE TO HAVE YOU HERE
          </p>
          <p className="mb-6 text-2xl text-slate-900">
            I&apos;m{" "}
            <strong className="transition duration-300 hover:text-white">
              Dante Alvarez
            </strong>
            , a full-stack developer, engineer, and designer.
          </p>
        </div>
        <div className="my-5 w-fit">
          <p className="mb-2 text-sm font-bold tracking-widest text-white">
            GET IN TOUCH
          </p>
          <span className="mb-5 text-2xl text-white">
            <a
              href="mailto:dante.alvarez.pr@gmail.com"
              className="animated w-fit"
            >
              dante.alvarez.pr@gmail.com
            </a>
          </span>
        </div>
      </div>

      <div className="bg-dark-yellow flex items-center justify-center border-b-4 p-5 md:col-start-1 md:row-span-2 md:row-start-3 lg:col-span-1 lg:col-start-1 lg:row-start-3">
        <div className="my-3 w-fit">
          <p className="mb-3 p-3 text-center text-lg font-bold tracking-widest text-white">
            LOOK AROUND
          </p>
          <p className="mx-1 text-center text-base md:text-xl">
            I can help you build a product, feature or a website. If you like
            what you see and have a fun idea you need coded, don&apos;t hesitate
            and <br />
            <span className="text-2xl text-white">
              <a
                href="mailto:dante.alvarez.pr@gmail.com"
                className="animated w-fit"
              >
                send me a message!
              </a>
            </span>
          </p>
        </div>
      </div>
      <ProjectCard
        project={projectsList[0].project}
        color={"dark-blue"}
        locationClasses={
          "bg-[length:9rem_9rem] lg:bg-[length:20rem_20rem] md:bg-[length:9rem_9rem] bg-no-repeat bg-[right_1rem_top_1rem] lg:bg-center md:bg-center lg:col-start-1 lg:col-span-2 lg:row-start-1 lg:row-span-2 md:row-start-2 md:col-start-1 md:col-span-2"
        }
        bgImg={projectsList[0].bgImg}
      />

      <ProjectCard
        project={projectsList[4].project}
        color={"dark-orange"}
        locationClasses={
          "bg-[length:9rem_9rem] lg:bg-[length:16rem_16rem] md:bg-[length:16rem_16rem] bg-[right_1rem_top] bg-no-repeat lg:bg-center md:bg-center lg:row-start-3 lg:col-start-2 lg:row-span-1 lg:col-span-3 md:col-span-2"
        }
        bgImg={projectsList[4].bgImg}
      />
      <ProjectCard
        project={projectsList[2].project}
        color={"medium-green"}
        locationClasses={
          "bg-[length:11rem_11rem] lg:bg-[length:20rem_20rem] md:bg-center bg-[right_1rem_top_-1rem] md:bg-[length:12rem_12rem] bg-no-repeat bg-center lg:row-start-2 lg:col-start-3 lg:row-span-1 lg:col-span-2 md:row-start-2"
        }
        bgImg={projectsList[2].bgImg}
      />
      <ProjectCard
        project={projectsList[1].project}
        color={"dark-yellow"}
        locationClasses={
          "bg-[length:9rem_9rem] lg:bg-[length:11rem_11rem] md:bg-[length:11rem_11rem] bg-no-repeat bg-[right_1rem_top_1rem] lg:bg-[center_top_3.5rem] md:bg-[center_top_3.5rem] lg:col-start-3 lg:row-span-1 lg:col-span-1 lg:row-start-1 md:row-start-1"
        }
        bgImg={projectsList[1].bgImg}
      />
      <ProjectCard
        project={projectsList[3].project}
        color={"medium-orange"}
        locationClasses={
          "bg-[length:9rem_9rem] lg:bg-[length:13rem_13rem] md:bg-[length:10rem_10rem] bg-no-repeat bg-[right_top_1rem] lg:bg-center md:bg-center lg:row-start-2 lg:col-start-5 lg:row-span-2 md:row-start-4 md:col-start-2 md:col-span-2"
        }
        bgImg={projectsList[3].bgImg}
      />
    </main>
  );
}

export default Homepage;
