import React from "react";
import { Link } from "react-router-dom";

function ProjectCard({ project, color, locationClasses, bgImg }) {
  const textColor = (color) => {
    switch (color) {
      case "dark-blue":
        return "#264653";
      case "dark-yellow":
        return "#BFA156";
      case "dark-orange":
        return "#B85840";
      case "medium-orange":
        return "#CC8649";
      case "medium-green":
        return "#279185";
      default:
        return "no-color";
    }
  };

  return (
    <div
      className={`group border-b-4 bg-${color} bg-cover ${bgImg} ${locationClasses}`}
    >
      <div className="flex h-full flex-col justify-between group-hover:hidden">
        <div className="ml-3 p-3 pt-4 text-3xl font-bold text-white">
          {project.title}
        </div>
        <div className="grid grid-cols-3 gap-5 p-3 text-xs text-white">
          <span
            className={`col-start-1 bg-${color} min-w-fit border border-white p-3`}
          >
            {project.type}
          </span>
          <span
            className={`col-span-2 col-start-2 bg-${color} py-auto flex items-center border border-white p-3`}
          >
            {project.tools}
          </span>
        </div>
      </div>

      {/* HOVER DISPLAY */}

      <div
        className={`align-center hidden h-full flex-col justify-center p-5 text-white opacity-0 group-hover:flex bg-${color}  transition duration-700 hover:opacity-100`}
      >
        <p className="mb-5 p-5 text-center text-xl">
          {project.short_description}
        </p>
        <Link
          to={`/project/${project.id}`}
          key={project.id}
          style={{ color: `${textColor(color)}` }}
          className="hover-text mx-auto flex w-1/2 justify-center border border-white bg-white p-2 text-sm transition duration-300 hover:bg-transparent hover:text-white"
        >
          <button className="text-center">VIEW {project.type}</button>
        </Link>
      </div>
    </div>
  );
}

export default ProjectCard;
