import { useParams } from "react-router-dom";
import projectsList from "../projects/projects";

function ProjectPage() {
  window.scrollTo(0, 0);
  const { projectId } = useParams();
  const currentItem = projectsList.filter(
    (item) => item.project.id === parseInt(projectId, 10)
  )[0];

  const textColor = (color) => {
    switch (color) {
      case "dark-blue":
        return "#264653";
      case "dark-yellow":
        return "#BFA156";
      case "dark-orange":
        return "#B85840";
      case "medium-orange":
        return "#CC8649";
      case "medium-green":
        return "#279185";
      default:
        return "no-color";
    }
  };

  return (
    <main className="h-fit">
      <section
        className={`md:flex lg:flex bg-${currentItem.color} flex flex-col py-12 md:flex-row md:py-9 lg:flex-row lg:py-9`}
      >
        <div className="md:w-34 h-fit w-full p-8 md:p-14 lg:w-3/4 lg:p-14">
          <h1 className="text-4xl font-extrabold text-white md:text-6xl lg:text-6xl">
            {currentItem.project.title}
          </h1>
          <h2 className="my-5 text-lg font-bold tracking-widest text-black">
            PROJECT BACKGROUND
          </h2>
          <p className="text-justify text-base font-semibold leading-loose text-white md:text-lg lg:text-xl">
            {currentItem.project.full_description}
          </p>
          <p className="mt-10 text-lg font-bold tracking-widest text-black">
            TOOLS & TECH
          </p>
          <div className="grid grid-cols-3 gap-5 p-3 pl-0 text-lg text-white lg:w-1/2">
            <span
              className={`col-span-2 col-start-1 bg-${currentItem.color} py-auto flex items-center border border-white p-3`}
            >
              {currentItem.project.tools}
            </span>
          </div>
        </div>
        <div
          className={`bg-${currentItem.color} hidden h-fit p-14 md:mb-10 md:block lg:mb-10 lg:block`}
        >
          <h2 className="mb-5 text-lg font-bold tracking-widest text-black">
            SERVICES
          </h2>
          <div className="my-5 text-xl font-semibold leading-loose text-white">
            <ul>
              {currentItem.services.map((service, index) => {
                return <li key={index}>{service}</li>;
              })}
            </ul>
          </div>
        </div>
      </section>

      <section className="mb-10  h-full grid-cols-3 grid-rows-3 bg-[#F4F4F4] lg:grid">
        <img
          alt="project"
          src={currentItem.images[1]}
          className="mx-auto w-full self-center lg:col-span-2 lg:col-start-1 lg:row-start-1"
        />
        <div
          className={`h-full bg-[#F4F4F4] lg:col-span-1 lg:col-start-3 lg:row-start-1 text-${currentItem.color} flex items-center p-10 text-xl tracking-widest md:p-20 md:text-3xl lg:p-20 lg:text-3xl`}
        >
          <p className="text-lg">{currentItem.project.middle_description[0]}</p>
        </div>
        <div className="flex items-center justify-center bg-[#F4F4F4] md:hidden lg:flex">
          <div className="w-11/12">
            <img
              alt="project0"
              src={currentItem.images[0]}
              className="mx-auto my-5 w-2/3 lg:col-span-1 lg:col-start-1 lg:row-start-2 lg:my-0 lg:w-full"
            />
          </div>
        </div>

        <div
          className={`lg:col-span-2 lg:col-start-2 lg:row-start-2 bg-${currentItem.color} flex items-center p-10 text-lg tracking-widest text-white md:justify-between md:p-20 md:text-3xl lg:p-20 lg:text-3xl`}
        >
          <p className="text-lg md:w-1/2 lg:w-full">
            {currentItem.project.middle_description[1]}
          </p>
          <div className="mx-4 hidden justify-center md:flex md:w-1/2 lg:hidden">
            <img
              alt="project"
              src={currentItem.images[0]}
              className="mx-auto w-full border-4 lg:col-span-1 lg:col-start-1 lg:row-start-2"
            ></img>
          </div>
        </div>

        <div className="flex items-center justify-center bg-[#F4F4F4] lg:col-span-2 lg:col-start-2 lg:row-start-3">
          <img alt="project" src={currentItem.images[2]} className="my-3"></img>
        </div>
        <div
          className={`align-center flex h-full flex-col justify-around p-10 text-white md:py-40 lg:py-40 bg-${currentItem.color}`}
        >
          <p className="mb-5 text-center text-xl md:mb-20">
            {currentItem.project.short_description}
          </p>
          <div>
            <a
              rel="noreferrer"
              target="_blank"
              style={{ color: `${textColor(currentItem.color)}` }}
              href={currentItem.links[0]}
              className={`hover-text mx-auto flex w-1/2 justify-center border border-white bg-white p-2 text-sm transition duration-300 hover:bg-transparent hover:text-white`}
            >
              <button className="text-center">SEE SOURCE CODE</button>
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href={currentItem.links[1]}
              style={{ color: `${textColor(currentItem.color)}` }}
              className={`hover-text mx-auto mt-5 flex w-1/2 justify-center border border-white bg-white p-2 text-sm transition duration-300 hover:bg-transparent hover:text-white`}
            >
              <button className="text-center">SEE LIVE DEMO</button>
            </a>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ProjectPage;
