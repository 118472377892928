import { NavLink } from "react-router-dom";

function Header() {
  return (
    <header className="fixed z-50 mt-6 h-fit w-full">
      <nav className="flex h-fit justify-end">
        <NavLink
          to="/"
          className="mx-4 rounded-sm border border-white bg-white px-5 py-2 tracking-widest transition duration-300 hover:bg-transparent hover:text-white"
        >
          HOME
        </NavLink>
        <NavLink
          to="/about"
          className="mx-4 rounded-sm border border-white bg-white px-5 py-2 tracking-widest transition duration-300 hover:bg-transparent hover:text-white"
        >
          ABOUT
        </NavLink>
        {/* <NavLink to="/city" className="bg-white px-5 py-2 mx-4 border hover:bg-transparent rounded-sm transition duration-300 border-white tracking-widest hover:text-white">CDMX GUIDE</NavLink> */}
      </nav>
    </header>
  );
}

export default Header;
