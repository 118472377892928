const projectsList = [
  {
    project: {
      title: "bowl-of-surreal",
      type: "CASE STUDY",
      tools: "REACT, REDUX, TAILWIND",
      short_description:
        "Bowl-of-surreal is a fun little visual treat to enjoy while having a bowl of cereal in the morning. It displays an awesome art catalog for you.",
      full_description:
        "What is art? I have never decided on a final personal answer for this question and often I prefer hearing other people’s opinions on the matter. Which brings us to another truly important question: where is art? Among the almost unlimited delights of the digital era, I am a big fan of open APIs that offer data to whoever feels like doing something cool with it. The Art Institute of Chicago is a great example. For this project I decided I wanted to bring art closer to people in an accessible, intuitive, efficient, and above all enjoyable and engaging way. This was possible thanks to the Art Institute of Chicago API.",
      middle_description: [
        "Every time you visit the site, it will display a random bowl of 20 artworks from the Art Institute of Chicago.",
        "Each item had a HSL color code assigned by the institute as part of the piece's details, which was used to add color to the UI. The goal was to present the data in an easy, colorful, and fun manner.",
      ],
      id: 1,
    },
    bgImg: "bg-bowl",
    color: "dark-blue",
    services: ["Design", "Development", "Deployment"],
    images: [
      "/imgs/bowl/bowl.gif",
      "/imgs/bowl/home.jpeg",
      "/imgs/bowl/mock.jpg",
    ],
    links: [
      "https://github.com/danalvrz/bowl-of-surreal",
      "https://bowl-of-surreal.netlify.app",
    ],
  },
  {
    project: {
      title: "taco-maniaco",
      type: "CASE STUDY",
      tools: "RUBY, RAILS, TAILWIND",
      short_description:
        "This is an app where you can add all of your best taco recipes, from one taco lover to another.",
      full_description:
        "I enjoy food very much. Talking about food is a big element of my obsession, and sharing recipes with family and friends is a fun way to do that. Tacos are a very interesting dish—in Mexican cuisine, “taco” is a generic term which can take many different forms. What they usually have in common is that they’re delicious. I thought this specific yet unlimited type of food was a perfect example to use for a recipe app, and it’s a nice way to share the tastes you love.",
      middle_description: [
        "After creating your user profile, you can add recipes using ingredients from the ingredients section, or by adding new ones.",
        "You can decide to make your recipes public or not; if a recipe is public it will be displayed for all visitors to the site, even if they don’t have an account. There’s a section to save your private recipes, too.",
      ],
      id: 2,
    },
    bgImg: "bg-taco",
    color: "dark-yellow",
    services: ["Design", "Development", "Deployment"],
    images: [
      "/imgs/taco/taco.gif",
      "/imgs/taco/home.jpeg",
      "/imgs/taco/mock.jpeg",
    ],
    links: [
      "https://github.com/danalvrz/taco-maniaco-ror",
      "https://taco-maniaco.herokuapp.com",
    ],
  },
  {
    project: {
      title: "alt-porfolio",
      type: "CASE STUDY",
      tools: "HTML, CSS, JAVASCRIPT",
      short_description:
        "An example of a professional website to display your best work.",
      full_description:
        "Any professional needs a website, which is a way to instantly communicate the value that they bring to any role they might fill. This is true regardless of where you’re located or really what industry you’re in. It’s important for this type of website to be mobile-friendly, since most web browsing happens from mobile devices. I wanted to create a portfolio template that’s engaging and visually appealing, something which brings interest as soon as you land on the site. This is a clean canvas and an adaptable, fresh template that anyone can use to enhance their professional presence online.",
      middle_description: [
        "This simple design example could be scaled up to add any number of additional features or pages.",
        "In the contact section there is a Formspree powered contact form so every message you get gets saved in a spreadsheet-like cloud based file, so the user can easily see them and do anything they want with them.",
      ],
      id: 3,
    },
    bgImg: "bg-portfolio",
    color: "medium-green",
    services: ["Design", "Development", "Deployment"],
    images: [
      "/imgs/portfolio/portfolio.gif",
      "/imgs/portfolio/home.jpeg",
      "/imgs/portfolio/mock.jpeg",
    ],
    links: [
      "https://github.com/danalvrz/Portfolio",
      "https://danalvrz.github.io/Portfolio/",
    ],
  },
  {
    project: {
      title: "penni",
      type: "CASE STUDY",
      tools: "RUBY, RAILS, TAILWIND",
      short_description:
        "This is an MVP to keep track of your expenses—don't let a single penny get lost!",
      full_description:
        "Apps are an important part of our lives. Some are just entertaining, but a lot of them aim to add something to our lives to make life easier—but they need to be designed well. I think the way to translate an idea effectively for the user is to keep things simple. This project makes it easy for the user to keep track of their spending and make a habit of recording every time they spend money. The keeps a record of every penny spent, including the amount, name, and custom category of the expense.",
      middle_description: [
        "This is a fully personalized app with a login feature; only the user has access to their information and expenses. Once you log in, you’ll see your own spending tracker, which you can easily customize.",
        "Other than customizing the name of the expense categories, I decided to include emojis—which are accessible, fun, and easy to use—as a way to edit them.",
      ],
      id: 4,
    },
    bgImg: "bg-penni",
    color: "medium-orange",
    services: ["Design", "Development", "Deployment"],
    images: [
      "/imgs/penni/penni.gif",
      "/imgs/penni/home.jpeg",
      "/imgs/penni/mock.jpeg",
    ],
    links: [
      "https://github.com/danalvrz/pennymunch",
      "https://pennitracker.herokuapp.com",
    ],
  },
  {
    project: {
      title: "event-summit",
      type: "CASE STUDY",
      tools: "HTML, CSS, JAVASCRIPT",
      short_description:
        "This is a spoof event website template based on an original idea from designer Cindy Shin, with content inspired by the Beef and Dairy Network Podcast.",
      full_description:
        "Anytime there’s any sort of event—a collective happening, a gathering of people or ideas—you need to explain what’s going to happen to the public. There is always a need for websites that share ideas so that people can come together and make them happen. The internet provides an unprecedented way for people to connect and share, so whether we’re talking about a live event or any other collective effort, we need a way to easily communicate the essential information.",
      middle_description: [
        "The site makes it possible to display information about keynote speakers, conferences, concerts, or other happenings in an accessible way. The schedule is clear with information about what’s going on and how to participate, so people know immediately how, when, and where to join.",
        "In terms of mobile browsing, it’s important to keep things minimal and only offer the most crucial information. Additionally, the user should be able to request more details if they need them; I’ve done this using features like pop-up menus and interactive lists.",
      ],
      id: 5,
    },
    bgImg: "bg-beef",
    color: "dark-orange",
    services: ["Development", "Deployment"],
    images: [
      "/imgs/summit/summit.gif",
      "/imgs/summit/home.jpeg",
      "/imgs/summit/mock.jpeg",
    ],
    links: [
      "https://github.com/danalvrz/event-template",
      "https://danalvrz.github.io/event-template/",
    ],
  },
];

export default projectsList;
